'use client'

import Button from '@dg/common/components/Button'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	XMarkIcon
} from '@heroicons/react/24/outline'
import {
	ReactNode, type RefObject
} from 'react'

interface ToastViewProps {
	className: string;
	closeBtn: boolean;
	height: string;
	id: string;
	text: ReactNode;
	toastCloseFunc: () => void;
	toastRef: RefObject<HTMLDivElement | null>;
	toastZIndex: string;
	transition?: boolean;
	width: string;
	wrapHidden: boolean;
	wrapOpacity: boolean;
}

const ToastView = ({
	toastRef,
	text,
	id,
	className,
	wrapHidden,
	wrapOpacity,
	toastZIndex,
	width,
	height,
	closeBtn,
	transition = true,
	toastCloseFunc
}: ToastViewProps) => (
	<div
		className={
			classNameFunc(`
				toast-wrap
				${closeBtn === false ? `text-center` : ``}
				${wrapHidden === true ? `invisible overflow-hidden` : ``}
			`)
		}
	>
		<div
			id={id}
			ref={toastRef}
			className={
				classNameFunc(`
					fixed left-1/2 min-w-32 max-w-9/10 -translate-x-1/2 overflow-hidden rounded-md
					bg-dg-black/80 shadow-lg shadow-dg-black/10
					dark:bg-dgdark-black/80 dark:shadow-dgdark-black/10
					${transition === true ? `transition-opacity` : ``}
					${wrapOpacity === true ? `opacity-0` : `opacity-100`}
					${toastZIndex}
					${width}
					${height}
					${className}
				`)
			}
		>
			<div
				className="flex text-dg-white dark:text-dgdark-white"
			>
				<div
					className="order-1 block w-full p-4"
				>
					{
						typeof text !== `string` ?
							text :
							(
								<div
									dangerouslySetInnerHTML={
										{
											__html: text
										}
									}
								/>
							)
					}
				</div>

				{
					closeBtn === true && (
						<Button
							className="order-2 -ml-2 block min-h-full w-10 flex-none p-2"
							onClick={toastCloseFunc}
							hover
						>
							<XMarkIcon />

							<span
								className="sr-only"
							>
								Close
							</span>
						</Button>
					)
				}
			</div>
		</div>
	</div>
)

export default ToastView
