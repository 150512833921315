'use client'

import FooterView from '@dg/common/components/Mobile/Footer/FooterView/FooterView'

interface FooterProps {
	type: `empty` | `navOnly` | `sub` | `subOnly`;
}

const Footer = ({
	type
}: FooterProps) => {
	const props = {
		type
	}

	return (
		<FooterView
			{...props}
		/>
	)
}

export default Footer
