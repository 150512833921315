'use client'

import HeaderView from '@dg/common/components/Pc/Header/HeaderView/HeaderView'
import {
	logoutApi
} from '@dg/common/lib/api'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import useLogin from '@dg/common/lib/hooks/useLogin'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	usePathname, useRouter, useSearchParams
} from 'next/navigation'
import {
	useCallback
} from 'react'
import {
	useTranslation
} from 'react-i18next'

interface AppHeaderProps {
	adminLogin: boolean;
	title?: string;
	type: `empty` | `headerOnly` | `logoOnly` | `main` | `sub` | `subHeaderOnly`;
}

const AppHeader = ({
	title = ``, type = `empty`, adminLogin
}: AppHeaderProps) => {
	const {
		t
	} = useTranslation()

	const router = useRouter()
	const pathname = usePathname() ?? ``
	const searchParams = useSearchParams() ?? new URLSearchParams()

	/* Member PageRouter 지원 종료 시 변경
		const pathname = usePathname()
		const searchParams = useSearchParams()
	*/

	const login = useLogin()
	const layerpopup = useLayerpopup()

	const loginChk =
		pathname.split(`/`)[2] === `join` &&
		pathname.split(`/`)[3] !== `moreinfo` &&
		pathname.split(`/`)[3] !== `email`

	const routerLoginChk = pathname.split(`/`)[2] === `login`

	const logoutSetApi = useSetApi({
		api: logoutApi,
		successCallback: () => {
			login.set({
				flag: false,
				userData: ``
			})

			layerpopup.close(`layerpopup-logoutinfo`)

			layerpopup.open({
				id: `layerpopup-alert`,
				text: t(`로그아웃했습니다. Qoo10을 이용해 주셔서 감사합니다.`)
			})

			setTimeout(() => {
				router.push(pathsData.home)
			}, 500)
		}
	})

	const logoutFunc = useCallback(() => {
		logoutSetApi.set()
	}, [
		logoutSetApi
	])

	const props = {
		adminChk: adminLogin,
		loginMenuSelected: `${pathname.split(`/`)[2] === `join` ? `join` : ``}${pathname.split(`/`)[2] === `login` ? `login` : ``}`,
		loginMenuVisible: routerLoginChk === true || loginChk === true,
		loginSeller: searchParams.get(`seller`) === `true`,
		logoutFunc,
		title,
		type
	}

	return (
		<HeaderView
			{...props}
		/>
	)
}

export default AppHeader
