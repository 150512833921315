const imageloader = ({
	src,
	width,
	quality
}: {
	quality: number;
	src: string;
	width: number;
}) => {
	let customSrc = src

	if (customSrc.match(/gd.image-qoo10.jp/gim) !== null || customSrc.match(/dp.image-qoo10.jp/gim) !== null) {
		const customSrcArray = Array.from(customSrc.split(`.`))

		const customSrcJoin = customSrcArray.map((item, index) => {
			if (index < customSrcArray.length - 1) {
				return item
			}

			return ``
		}).join(`.`)

		if (src.match(/gd.image-qoo10.jp/gim)) {
			const joinComma = src.match(/.g_/gim) ? `.` : ``

			customSrc = `${customSrcJoin.split(`.g_`)[0]}${joinComma}g_${width}-w-st_g.${customSrcArray[customSrcArray.length - 1]}`

			return customSrc
		}

		if (src.match(/dp.image-qoo10.jp/gim)) {
			const joinComma = src.match(/.s_/gim) ? `.` : ``

			customSrc = `${customSrcJoin.split(`.s_`)[0]}${joinComma}s_${width}-w-fs_s.${customSrcArray[customSrcArray.length - 1]}`

			return customSrc
		}
	}

	const addParams = `${src.match(/\?/gim) !== null ? `&` : `?`}w=${width}&q=${quality || 75}` // &v=${process.env.buildId}

	return `${src}${addParams}`
}

export default imageloader
