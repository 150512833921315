'use client'

import Layerpopup from '@dg/common/components/Layerpopup'
import Link from '@dg/common/components/Link'
import HeaderInfoLiView from '@dg/common/components/Pc/Header/HeaderView/HeaderInfo/HeaderInfoView/HeaderInfoLiView'
import {
	type HeaderApiProps
} from '@dg/common/lib/api'
import {
	classNameFunc
} from '@dg/common/lib/common'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import gradeData from '@dg/common/public/json/grade'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	useTranslation
} from 'react-i18next'

interface HeaderInfoViewProps {
	data?: HeaderApiProps[`result`];
	login: boolean;
	logoutFunc: () => void;
	memberGroupName: HeaderApiProps[`result`][`buyer_gr`] | `GUEST`;
}

const HeaderInfoView = ({
	data, login, memberGroupName, logoutFunc
}: HeaderInfoViewProps) => {
	const layerpopup = useLayerpopup()

	const {
		t
	} = useTranslation()

	const memberGroup = gradeData.customerGrade[memberGroupName]

	return (
		<>
			<ul
				className="absolute -top-3 right-0 flex items-center text-xs"
			>
				{
					data?.cust_nm && (
						<>
							{
								login === true && (
									<li
										className="flex items-center space-x-1.5 pr-1"
									>
										{
											memberGroupName !== `G1` &&
											memberGroupName !== `B1` &&
											memberGroupName !== `GUEST` &&
											(
												<Link
													href={`${pathsData.qchance}#reward`}
													className={
														classNameFunc(`
															flex justify-center rounded border border-solid
															${memberGroup?.colorFill || ``}
														`)
													}
													px
												>
													{memberGroup?.name}
												</Link>
											)
										}

										<Link
											className={classNameFunc(`pr-1 ${memberGroup?.colorText}`)}
											href={pathsData.myMain}
										>
											{data?.cust_nm.slice(0, 15)}

											{data?.cust_nm.length > 15 ? `...` : ``}
										</Link>

										{t(`님`)}
									</li>
								)
							}

							<HeaderInfoLiView
								link={login !== true ? pathsData.login : undefined}
								onClick={
									() => {
										if (login === true) {
											layerpopup.open({
												callbackFunc: () => logoutFunc(),
												id: `layerpopup-logoutinfo`
											})
										}
									}
								}
							>
								{
									login === true ?
										t(`로그아웃`) :
										t(`로그인`)
								}
							</HeaderInfoLiView>
						</>
					)
				}

				<HeaderInfoLiView
					link={pathsData.myMain}
				>
					{t(`My Qoo10`)}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.cart}
				>
					{t(`장바구니`)}

					{
						data !== undefined && data.cart_cnt > 0 &&
						`(${data.cart_cnt})`
					}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={`window_${pathsData.qpost}_815_786`}
				>
					Qpost
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.qsm}
					target="blank"
				>
					QSM
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.help}
				>
					{t(`도움말`)}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.openStore}
				>
					{t(`Qoo10에 출점`)}
				</HeaderInfoLiView>

				<HeaderInfoLiView
					link={pathsData.university}
					last
				>
					{t(`Qoo10대학`)}
				</HeaderInfoLiView>
			</ul>

			<Layerpopup
				id="layerpopup-logoutinfo"
				defaultFooter
			>
				{t(`로그아웃했습니다. Qoo10을 이용해 주셔서 감사합니다.`)}
			</Layerpopup>
		</>
	)
}

export default HeaderInfoView
