'use client'

import HeaderInfoView from '@dg/common/components/Pc/Header/HeaderView/HeaderInfo/HeaderInfoView/HeaderInfoView'
import {
	headerApi,
	type HeaderApiProps
} from '@dg/common/lib/api'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useLogin from '@dg/common/lib/hooks/useLogin'
import {
	useQueryClient, UseQueryResult
} from '@tanstack/react-query'
import {
	useEffect, useMemo
} from 'react'

interface HeaderInfoProps {
	logoutFunc: () => void;
}

const HeaderInfo = ({
	logoutFunc
}: HeaderInfoProps) => {
	const login = useLogin()
	const queryClient = useQueryClient()

	const data = useMemo(() => ({
		loginStatus: false
	}), [])

	const headerDataApi = useGetApi({
		api: headerApi,
		name: `headerDataApi`
	}) as UseQueryResult<HeaderApiProps>

	useEffect(() => {
		const headerInfoRefetchFunc = async () => {
			await queryClient.refetchQueries({
				queryKey: [
					`headerDataApi`
				]
			})
		}

		if (data.loginStatus !== login.info) {
			data.loginStatus = login.info

			headerInfoRefetchFunc()
		}
	}, [
		data,
		login.info,
		queryClient
	])

	const memberGroupName: HeaderApiProps[`result`][`buyer_gr`] | `GUEST` =
		headerDataApi.data?.result.member_kind === `M` ?
			headerDataApi.data?.result.buyer_gr :
			`GUEST`

	const props = {
		data: headerDataApi.data?.result,
		login: login.info,
		logoutFunc,
		memberGroupName
	}

	return (
		<HeaderInfoView
			{...props}
		/>
	)
}

export default HeaderInfo
