'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	commonData
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import NextLink, {
	LinkProps as NextLinkProps
} from 'next/link'
import {
	AnchorHTMLAttributes, PropsWithChildren
} from 'react'

interface LinkProps extends NextLinkProps {
	btnType?: `darkGray` | `lightGray` | `mobileRed` | `pcBlue` | `black`;
	className?: string;
	hover?: boolean;
	pd?: boolean;
	px?: boolean;
	py?: boolean;
	transition?: boolean;
}

const Link = (props: PropsWithChildren<LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>>) => {
	const {
		children, className = ``, href, btnType = ``,
		px = false, py = false, pd = false, hover = false, transition = true,
		...rest
	} = props

	const [
		appChk
	] = useAtom(commonData(`appChk`))

	const customClassName =
		classNameFunc(`
			${transition === true ? `transition-colors` : ``}
			${pd === true ? `p-4` : ``}
			${py === true ? `py-4` : ``}
			${px === true ? `px-4` : ``}
			${hover === true ? `hover:underline` : ``}
			${
				btnType === `pcBlue` ?
					`
						bg-[#517fef] text-dg-white disabled:bg-dg-500
						disabled:text-dg-white dark:text-dgdark-white
						dark:disabled:bg-dgdark-500 dark:disabled:text-dgdark-white
					` :
					``
			}
			${
				btnType === `mobileRed` ?
					`
						bg-dg-red text-dg-white disabled:bg-dg-500 disabled:text-dg-white
						dark:bg-dgdark-red dark:text-dgdark-white dark:disabled:bg-dgdark-500
						dark:disabled:text-dgdark-white
					` :
					``
			}
			${
				btnType === `darkGray` ?
					`
						bg-[#6e7188] text-dg-white disabled:bg-dg-500 disabled:text-dg-white
						dark:text-dgdark-white dark:disabled:bg-dgdark-500 dark:disabled:text-dgdark-white
					` :
					``
			}
			${
				btnType === `lightGray` ?
					`
						border border-solid border-dg-500 bg-dg-200 text-dg-900 disabled:bg-dg-200
						disabled:text-dg-900 dark:border-dgdark-500 dark:bg-dgdark-200
						dark:text-dgdark-900 dark:disabled:bg-dgdark-200 dark:disabled:text-dgdark-900
					` :
					``
			}
			${
				btnType === `black` ?
					`
						border border-solid border-dg-black bg-dg-black text-dg-white disabled:border-dg-500
						disabled:bg-dg-200 disabled:text-dg-900 dark:border-dgdark-500 dark:bg-dgdark-200
						dark:text-dgdark-900 dark:disabled:border-dgdark-500
						dark:disabled:bg-dgdark-200 dark:disabled:text-dgdark-900
					` :
					``
			}
			${className}
		`)

	return (
		<NextLink
			{...rest}
			className={customClassName}
			href={href}
			onClick={
				(event) => {
					// App 일 경우 Universal link 적용으로 다른 도메인으로 링크 연결 시 onClick 설정 필요
					if (
						appChk === true &&
						href.match(/https/) !== null &&
						href.match(/member.qoo10.jp/) === null &&
						href.match(/live.qoo10.jp/) === null
					) {
						if (event.cancelable === true) {
							event.preventDefault()
							event.stopPropagation()
						}

						window.open(href)
					}
				}
			}
		>
			{children}
		</NextLink>
	)
}

export default Link
