'use client'

import HeaderView from '@dg/common/components/Mobile/Header/HeaderView/HeaderView'
import {
	addCookie
} from '@dg/common/lib/common'
import {
	execApp, goAppStore, goAppPageFailStore, gaDimensionFunc
} from '@dg/common/lib/qoo10Common'
import {
	commonData
} from '@dg/common/lib/store'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	useAtom
} from 'jotai'
import {
	useRouter
} from 'next/navigation'
import {
	useCallback, useState
} from 'react'

interface HeaderProps {
	adminLogin: boolean;
	appBannerVisible: boolean;
	title?: string;
	type: `empty` | `headerOnly` | `headerOnlyAppBanner` | `main` | `sub` | `subAppBanner`;
}

const Header = ({
	title = ``, type, adminLogin, appBannerVisible
}: HeaderProps) => {
	const [
		os
	] = useAtom(commonData(`os`))

	const router = useRouter()

	const [
		pageData,
		setPageData
	] = useState(appBannerVisible === true && (type === `main` || type.match(/AppBanner/) !== null))

	const appBannerLinkFunc = useCallback(() => {
		if (os === `ios`) {
			execApp(`qoo10jp://`, ``, goAppStore)
		} else {
			goAppPageFailStore(``)
		}

		gaDimensionFunc({
			data: {
				ep_event_area: `app_banner`,
				ep_event_label: `click`,
				ep_event_page: `mweb`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})
	}, [
		os
	])

	const appBannerCloseFunc = useCallback(() => {
		addCookie(`appbanner=false`, 9999)

		setPageData(false)

		gaDimensionFunc({
			data: {
				ep_event_area: `app_banner`,
				ep_event_label: `close`,
				ep_event_page: `mweb`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})
	}, [])

	const historyBackChk = useCallback(() => {
		const url = new URL(window.location.href).pathname

		const findChk = document.referrer.match(/\/mobile\/login/) !== null && url.match(/\/mobile\/find/gim) !== null

		if (window.opener !== null && url === `/mobile/login`) {
			window.close()
		} else if (
			url.match(/\/mobile\/join\/validate/gim) !== null ||
			url.match(/\/mobile\/join\/complete/gim) !== null ||
			findChk === true
		) {
			router.replace(pathsData.home)
		} else if (url.match(/\/mobile\/myinfo\/bank/gim) !== null) {
			router.replace(pathsData.myInfo)
		} else {
			router.back()
		}
	}, [
		router
	])

	const props = {
		adminChk: adminLogin,
		appBannerCloseFunc,
		appBannerLinkFunc,
		bannerVisible: pageData,
		historyBackChk,
		title,
		type
	}

	return (
		<HeaderView
			{...props}
		/>
	)
}

export default Header
