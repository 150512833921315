'use client'

import {
	ProvidersErrorProps
} from '@dg/common/components/Providers'
import {
	useQuery, QueryFunction, UseQueryOptions, QueryKey
} from '@tanstack/react-query'

interface UseGetApiProps {
	api: QueryFunction;
	enabled?: boolean;
	errorCallback?: (error: ProvidersErrorProps) => void;
	gcTime?: number;
	initialData?: unknown;
	name: string;
	successCallback?: (successData: never) => void;
}

const useGetApi = ({
	name, api, gcTime = 600000, enabled = true, initialData, successCallback, errorCallback
}: UseGetApiProps) => {
	const useGetApiQuery = useQuery({
		enabled,
		errorCallback,
		gcTime,
		initialData,
		queryFn: api,
		queryKey: [
			`${name}`
		],
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: gcTime,
		successCallback
	} as unknown as UseQueryOptions<unknown, Error, unknown, QueryKey>)

	return useGetApiQuery
}

export default useGetApi
