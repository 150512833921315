'use client'

import LayoutView from '@dg/common/components/Layout/LayoutView/LayoutView'
import Footer from '@dg/common/components/Mobile/Footer'
import Header from '@dg/common/components/Mobile/Header'
import FooterPc from '@dg/common/components/Pc/Footer'
import HeaderPc from '@dg/common/components/Pc/Header'
import {
	affiliateApi
} from '@dg/common/lib/api'
import {
	addCookie, device, getFooterType, getHeaderType, getPageTitle
} from '@dg/common/lib/common'
import useLogin from '@dg/common/lib/hooks/useLogin'
import useSetApi from '@dg/common/lib/hooks/useSetApi'
import * as qoo10Common from '@dg/common/lib/qoo10Common'
import {
	appHeaderTitle, appHeaderBtnFunc, appFooterTabFunc
} from '@dg/common/lib/qoo10Common'
import {
	commonData, commonLoadingValue
} from '@dg/common/lib/store'
import localeJa from '@dg/common/public/locales/ja/common.json'
import i18n from 'i18next'
import {
	useAtom
} from 'jotai'
import localeJaLive from 'live/public/locales/ja/common.json'
import localeJaMember from 'member/public/locales/ja/common.json'
import {
	usePathname, useSearchParams
} from 'next/navigation'
import {
	PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import type {
	LayoutDataProps
} from '@dg/common/components/Layout/LayoutView/LayoutView'

declare global {
	interface Window {

		/* Device Token 가져오는 함수 */
		setMobileDeviceTokenFunc: (value: string) => void;
	}
}

interface LayoutProps {
	adminLogin: boolean;
	appBannerVisible: boolean;
	appChk: boolean;
	appName: `qoo10` | `move`;
	className?: string;
	deviceInfo: `mobile` | `pc`;
	os: `ios` | `android` | `desktop`;
}

const Layout = ({
	children, className = ``, deviceInfo, appChk, appName, os, appBannerVisible, adminLogin
}: PropsWithChildren<LayoutProps>) => {
	const appConfigData = JSON.parse(process.env.appConfig ?? `{"npm_package_name":""}`) as {
		npm_package_name: `live` | `member`;
	}

	i18n.addResourceBundle(`ja`, `translation`, localeJa)

	if (appConfigData.npm_package_name === `member`) {
		i18n.addResourceBundle(`ja`, `translation`, localeJaMember)
	} else if (appConfigData.npm_package_name === `live`) {
		i18n.addResourceBundle(`ja`, `translation`, localeJaLive)
	}

	const layoutRef = useRef<HTMLDivElement>(null)

	const [
		,
		setAdminLogin
	] = useAtom(commonData(`adminLogin`))

	const [
		,
		setAppChk
	] = useAtom(commonData(`appChk`))

	const [
		,
		setAppName
	] = useAtom(commonData(`appName`))

	const [
		,
		setOs
	] = useAtom(commonData(`os`))

	const [
		,
		setAppToken
	] = useAtom(commonData(`appToken`))

	const [
		,
		setDeviceInfo
	] = useAtom(commonData(`deviceInfo`))

	const [
		title
	] = useAtom(commonData(`title`))

	const [
		loading
	] = useAtom(commonLoadingValue)

	const pathname = usePathname()
	const searchParams = useSearchParams()

	const login = useLogin()

	let pageName = getPageTitle(pathname, searchParams)

	let pageTitle = `${pageName} - ${title}`

	if (pageName === undefined) {
		pageName = `${title} - ネット通販｜eBay Japan`
		pageTitle = pageName
	}

	const data = useMemo(() => ({
		appTabBar: `` as `N` | `Y`,
		delayAppFooterChkFunc: null as unknown as ReturnType<typeof setTimeout>,
		delayRouteChkFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		theme: ``
	})

	const loginFunc = useCallback(() => {
		const jwtCookieName = `jwtaccess=`
		const jwtLogoutCookieName = `jwtlogout=`

		if (typeof window !== `undefined` && login.info === undefined) {
			if (
				document.cookie.match(jwtCookieName) !== null &&
				document.cookie.split(jwtCookieName)[1]?.split(`;`)[0] !== ``
			) {
				login.set({
					flag: true,
					userData: document.cookie.split(jwtCookieName)[1].split(`;`)[0]
				})
			} else if (
				document.cookie.match(jwtCookieName) === null ||
				document.cookie.match(jwtLogoutCookieName) !== null
			) {
				login.set({
					flag: false,
					userData: ``
				})
			}
		}
	}, [
		login
	])

	const headerChkFunc = useCallback(() => {
		/* 로그인 처리 */
		loginFunc()

		if (appChk === false) {
			return getHeaderType(pathname)
		}

		if (login.info !== undefined) {
			appHeaderTitle(pageName, login.info)
		}

		let headerRightChk: `D` | `P` = `D`

		if (getHeaderType(pathname).match(/headerOnly/) !== null) {
			headerRightChk = `P`
		}

		appHeaderBtnFunc(headerRightChk)

		return `empty`
	}, [
		appChk,
		login.info,
		loginFunc,
		pageName,
		pathname
	])

	const footerChkFunc = useCallback((forceUpdate = false) => {
		const url = pathname.split(`/`)

		if (appChk === false) {
			return getFooterType(pathname)
		}

		const memberappFooterChk = getFooterType(pathname) === `navOnly` && url[2] !== `find`
		const liveappFooterChk = getFooterType(pathname) !== `empty`

		const footerTabChk: `Y` | `N` =
			memberappFooterChk === true ||
			liveappFooterChk === true ?
				`Y` :
				`N`

		if (data.appTabBar !== footerTabChk || forceUpdate === true) {
			data.appTabBar = footerTabChk

			clearTimeout(data.delayAppFooterChkFunc)
			data.delayAppFooterChkFunc = setTimeout(() => {
				appFooterTabFunc(footerTabChk)
			}, 100)
		}

		return `empty`
	}, [
		appChk,
		data,
		pathname
	])

	const fixedPcFunc = useCallback(() => {
		const fixPcVersionCookieName = `FixPcVersion=`

		if (document.cookie.match(fixPcVersionCookieName) !== null) {
			addCookie(`${fixPcVersionCookieName}Y`, 1)
		}
	}, [])

	const affiliateDataApi = useSetApi({
		api: affiliateApi
	})

	const jaehuIdChkFunc = useCallback(() => {
		const jaehuId = searchParams.get(`jaehuid`) ?? ``

		const jaehuIdCookieName = `frontjaehuid=`

		const cookieJaehuId = document.cookie.match(jaehuIdCookieName) !== null ? atob(decodeURIComponent(document.cookie.split(jaehuIdCookieName)[1].split(`;`)[0]).replace(/"/gim, ``)) : ``

		if (jaehuId !== `` || cookieJaehuId === ``) {
			if (jaehuId !== cookieJaehuId) {
				const formData = new URLSearchParams()

				formData.append(`value`, jaehuId)

				affiliateDataApi.set(formData)
			}
		}
	}, [
		affiliateDataApi,
		searchParams
	])

	const changeThemeFunc = useCallback((loaded = false) => {
		let chgTheme = window.localStorage.getItem(`appTheme`) === `dark` ? `light` : `dark`

		if (loaded === true) {
			chgTheme =
				window.localStorage.getItem(`appTheme`) === null ||
				window.localStorage.getItem(`appTheme`) === `light` ?
					`light` :
					`dark`
		}

		setPageData({
			theme: chgTheme
		})

		window.localStorage.setItem(`appTheme`, chgTheme)
	}, [])

	const goTopFunc = useCallback(() => {
		window.scrollTo({
			behavior: `smooth`,
			left: 0,
			top: 0
		})
	}, [])

	const createHeaderFunc = useCallback(() => {
		const mobileChk = pathname.split(`/`)[1] === `mobile` || deviceInfo === `mobile`

		return (
			mobileChk ?
				(
					<Header
						adminLogin={adminLogin}
						appBannerVisible={appBannerVisible}
						title={pageName}
						type={headerChkFunc() as LayoutDataProps[`mobileHeaderType`]}
					/>
				) :
				(
					<HeaderPc
						adminLogin={adminLogin}
						title={pageName}
						type={headerChkFunc() as LayoutDataProps[`pcHeaderType`]}
					/>
				)
		)
	}, [
		adminLogin,
		appBannerVisible,
		deviceInfo,
		headerChkFunc,
		pageName,
		pathname
	])

	const createFooterFunc = useCallback(() => {
		const mobileChk = pathname.split(`/`)[1] === `mobile` || deviceInfo === `mobile`

		return (
			mobileChk ?
				(
					<Footer
						type={footerChkFunc()}
					/>
				) :
				(
					<FooterPc
						type={footerChkFunc() as LayoutDataProps[`pcFooterType`]}
					/>
				)
		)
	}, [
		deviceInfo,
		footerChkFunc,
		pathname
	])

	const scrollBeforeFunc = useCallback(() => {
		const scrollY = Math.floor(window.scrollY)

		const btnTopObj = document.querySelector(`#wrap .btn-top`) as HTMLButtonElement

		if (
			(document.querySelector(`body`) as HTMLBodyElement).scrollHeight > window.innerHeight + 100 &&
			scrollY > 100
		) {
			if (btnTopObj?.classList.contains(`hide`) === true) {
				btnTopObj.classList.remove(`hide`)
			}
		} else if (btnTopObj?.classList.contains(`hide`) === false) {
			btnTopObj.classList.add(`hide`)
		}
	}, [])

	const resizeFunc = useCallback(() => {
		if (layoutRef.current !== null) {
			const layout = {
				adminBanner: document.querySelector(`#admin-banner`) as HTMLElement,
				appBanner: document.querySelector(`#app-banner`) as HTMLElement,
				content: document.querySelector(`#wrap-content`) as HTMLElement,
				footer: document.querySelector(`#wrap-footer`) as HTMLElement,
				footerInfo: document.querySelector(`#footer-info`) as HTMLElement,
				header: document.querySelector(`#wrap-header`) as HTMLElement
			}

			const {
				adminBanner, appBanner, content, footer, footerInfo, header
			} = layout

			if (content !== null) {
				const AdminBannerValue = adminBanner !== null ? adminBanner.offsetHeight : 0

				const AppBannerValue = appBanner !== null ? appBanner.offsetHeight : 0

				const headerValue =
					header !== null ? header.offsetHeight + parseFloat(window.getComputedStyle(header).marginBottom) : 0

				const footerValue =
					footer !== null ? footer.offsetHeight + parseFloat(window.getComputedStyle(footer).marginTop) : 0

				const footerUlValue =
					footer?.querySelector(`ul`) ?
						(footer?.querySelector(`ul`) as HTMLUListElement).offsetHeight :
						0

				const footerInfoValue =
					footerInfo !== null ? footerInfo.offsetHeight + parseFloat(window.getComputedStyle(footerInfo).marginTop) - (footerValue - footerUlValue) : 0

				const contentHeight =
					window.innerHeight - headerValue - footerValue - footerInfoValue - AppBannerValue - AdminBannerValue

				let contentPadding = 0

				content.style.minHeight = ``
				content.style.paddingBottom = ``

				contentPadding = parseFloat(window.getComputedStyle(content).paddingBottom) + (appChk === true ? 100 : 0) + (deviceInfo === `mobile` && footerInfo === null ? footerValue : 0)

				content.style.minHeight = `${contentHeight}px`
				content.style.paddingBottom = `${contentPadding}px`
			}
		}
	}, [
		appChk,
		deviceInfo
	])

	const documentVisibilityFunc = useCallback(() => {
		if (document.hidden === false) {
			footerChkFunc(true)
		}
	}, [
		footerChkFunc
	])

	const storeFunc = useCallback(() => {
		setAdminLogin(adminLogin)

		setAppChk(appChk)

		setDeviceInfo(deviceInfo)

		setAppName(appName)

		setOs(os)
	}, [
		adminLogin,
		appChk,
		appName,
		deviceInfo,
		os,
		setAdminLogin,
		setAppChk,
		setAppName,
		setDeviceInfo,
		setOs
	])

	useEffect(() => {
		/* 브라우저 정보 생성 */
		device(window.navigator.userAgent, false)

		/* store 설정 */
		storeFunc()

		/* FixPcVersion 처리 */
		fixedPcFunc()

		/* 제휴 ID 처리 */
		jaehuIdChkFunc()

		/* 테마 설정 */
		if (pageData.theme === ``) {
			changeThemeFunc(true)
		}
	}, [
		changeThemeFunc,
		fixedPcFunc,
		jaehuIdChkFunc,
		pageData.theme,
		storeFunc
	])

	useEffect(() => {
		scrollBeforeFunc()

		window.addEventListener(`scroll`, scrollBeforeFunc)

		resizeFunc()

		window.addEventListener(`resize`, resizeFunc)

		document.addEventListener(`visibilitychange`, documentVisibilityFunc)

		window.setMobileDeviceTokenFunc = (value: string) => {
			setAppToken(value)
		}

		/* 안드로이드 앱 키보드 노출 시 하단 fixed 영역 안올라오게 처리
		if (appChk === true) {
			if (os === `android`) {
				if (
					typeof window !== `undefined` &&
					typeof window.setSoftInputMode !== `undefined`
				) {
					window.setSoftInputMode(true)
				}
			}
		}
		*/

		const cleanup = () => {
			window.removeEventListener(`scroll`, scrollBeforeFunc)

			window.removeEventListener(`resize`, resizeFunc)

			document.removeEventListener(`visibilitychange`, documentVisibilityFunc)
		}

		return cleanup
	}, [
		documentVisibilityFunc,
		resizeFunc,
		scrollBeforeFunc,
		setAppToken
	])

	useEffect(() => {
		window.qoo10_func = {}

		for (const item of Object.keys(qoo10Common)) {
			window[item] =
				(
					qoo10Common as Record<string, (...args: unknown[]) => unknown>
				)[item]

			if (item === `setAppHeaderState`) {
				window.qoo10_func[item] =
					(
						qoo10Common as Record<string, (...args: unknown[]) => unknown>
					)[item]
			}
		}
	}, [])

	const props = {
		children,
		className,
		createFooterFunc,
		createHeaderFunc,
		deviceInfo,
		goTopFunc,
		layoutRef,
		loading,
		pageTitle,
		theme: pageData.theme
	}

	return (
		<LayoutView
			{...props}
		/>
	)
}

export default Layout
