'use client'

import Link from '@dg/common/components/Link'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import imageData from '@dg/common/public/json/imageData'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'
import type {
	SearchMobilePopupApiProps
} from '@dg/common/lib/mobile/api'

interface HeaderRightSearchLayerCategoryProps {
	list?: SearchMobilePopupApiProps[`result`][`categories`];
}

const HeaderRightSearchLayerCategory = ({
	list
}: HeaderRightSearchLayerCategoryProps) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	return (
		list && (
			<div
				className="relative border-t-[10px] border-solid border-dg-200 px-4 pt-9 dark:border-dgdark-200"
			>
				<strong
					className="text-base"
				>
					{t(`카테고리`)}
				</strong>

				<ul
					className="mb-15 mt-9 grid grid-cols-3"
				>
					{
						list
							.map((item, index) => (
								<li
									key={Object.keys(list)[index]}
									className="mb-7.5 w-full text-2xs"
								>
									<Link
										className="relative flex size-full flex-col items-center space-y-2.5"
										href={item.connect_url}
									>
										<span
											className="relative block size-18 px-1.5"
										>
											<Image
												alt={item.name}
												className="object-cover"
												height={72}
												onError={imageValidate.onError}
												placeholder={imageData.emptyImage}
												sizes="72px"
												src={item.image_url}
												width={72}
											/>
										</span>

										<p
											className="break-all px-4 text-center"
											dangerouslySetInnerHTML={
												{
													__html: item.name
												}
											}
										/>
									</Link>
								</li>
							))
					}
				</ul>
			</div>
		)
	)
}

export default HeaderRightSearchLayerCategory
