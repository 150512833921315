'use client'

import FooterInfoLiView from '@dg/common/components/Mobile/Footer/FooterView/FooterInfoLiView'
import {
	commonData
} from '@dg/common/lib/store'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	useAtom
} from 'jotai'
import {
	useTranslation
} from 'react-i18next'

const FooterInfoView = () => {
	const {
		t
	} = useTranslation()

	const [
		os
	] = useAtom(commonData(`os`))

	return (
		<ul
			className="flex flex-wrap py-1"
		>
			<FooterInfoLiView
				link={pathsData.company}
				target="_blank"
			>
				{t(`회사개요`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={pathsData.policy}
				target="_blank"
			>
				{t(`이용 약관`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={pathsData.agreement}
				target="_blank"
			>
				{t(`개인 정보 보호 정책`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={pathsData.cookiePolicy}
				target="_blank"
			>
				{t(`외부 전송 정책`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={pathsPcData.home}
			>
				{t(`PC버전`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={pathsData.university}
				target="_blank"
			>
				{t(`Qoo10대학`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={os === `android` ? pathsData.androidApp : pathsData.iosApp}
				target="_blank"
			>
				{t(`지금 앱 다운로드`)}
			</FooterInfoLiView>

			<FooterInfoLiView
				link={pathsData.home}
			>
				{t(`홈`)}
			</FooterInfoLiView>
		</ul>
	)
}

export default FooterInfoView
