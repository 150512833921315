'use client'

import HeaderRightAlarmView from '@dg/common/components/Mobile/Header/HeaderView/HeaderRightAlarm/HeaderRightAlarmView/HeaderRightAlarmView'
import {
	headerApi,
	type HeaderApiProps
} from '@dg/common/lib/api'
import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useLogin from '@dg/common/lib/hooks/useLogin'
import {
	UseQueryResult, useQueryClient
} from '@tanstack/react-query'
import {
	useEffect, useMemo
} from 'react'

interface HeaderRightAlarmProps {
	type?: `alarm` | `cart`;
}

const HeaderRightAlarm = ({
	type = `alarm`
}: HeaderRightAlarmProps) => {
	const login = useLogin()
	const queryClient = useQueryClient()

	const data = useMemo(() => ({
		loginStatus: false
	}), [])

	const headerDataApi = useGetApi({
		api: headerApi,
		name: `headerDataApi`
	}) as UseQueryResult<HeaderApiProps>

	useEffect(() => {
		const headerInfoRefetchFunc = async () => {
			await queryClient.refetchQueries({
				queryKey: [
					`headerDataApi`
				]
			})
		}

		if (data.loginStatus !== login.info) {
			data.loginStatus = login.info

			headerInfoRefetchFunc()
		}
	}, [
		data,
		login.info,
		queryClient
	])

	const props = {
		data: headerDataApi.data?.result,
		type
	}

	return (
		<HeaderRightAlarmView
			{...props}
		/>
	)
}

export default HeaderRightAlarm
